import React from 'react';
import stakingImage from './assets/images/staking_image.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={stakingImage} className="staking-image" alt="logo" />
      </header>
    </div>
  );
}

export default App;
